

"use client"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from '@/components/ui/dialog'
import { AllowListWallet } from '@/lib/schema'
import { handleAddAllowList } from '@/server-actions/add-allowlist'
import { handleRemoveAllowList } from '@/server-actions/remove-allowlist'
import { TournamentAllowListTable } from './TournamentAllowListTable'
import { useToast } from './ui/use-toast'

function AllowListModal({ setAllowListModalOpen, allowListModalOpen, refetchAllowList, currentAllowList, tournamentId }: { setAllowListModalOpen: (open: boolean) => void, allowListModalOpen: boolean, refetchAllowList: () => void, currentAllowList: AllowListWallet[], tournamentId: number }) {
    const { toast } = useToast()

    const handleOpenChange = () => {
        setAllowListModalOpen(!allowListModalOpen);
    };

    const handleAdd = async (wallet: string) => {
        await handleAddAllowList({
            addAllowListProps: {
                tournamentId: tournamentId,
                wallet: wallet
            }
        })
        toast({
            title: "Wallet Added",
            description: "The wallet has been added to the allowlist.",
        })
        refetchAllowList()
    }

    const handleRemove = async (wallet: string) => {
        // Remove from allowlist
        toast({
            title: "Wallet Removed",
            description: "The wallet has been removed from the allowlist.",
        })
        await handleRemoveAllowList({
            deleteAllowListProps: {
                tournamentId: tournamentId,
                wallet: wallet
            }
        })
        refetchAllowList()
    }

    return (
        <>
            <Dialog open={allowListModalOpen} onOpenChange={handleOpenChange}>
                <DialogContent className="sm:max-w-[625px]">
                    <DialogHeader>
                        <DialogTitle>Tournament Allowlist</DialogTitle>
                        <DialogDescription>
                            Manage the list of wallet addresses allowed to participate in this tournament. Only addresses on this list will be able to join and play.
                        </DialogDescription>
                    </DialogHeader>
                    <TournamentAllowListTable data={currentAllowList} handleRemove={handleRemove} handleAdd={handleAdd} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AllowListModal


