"use client"

import useClerkSWR from "@/hooks/useClerkHook";
import { Tournament } from "@/lib/schema";
import { useState } from "react";
import { ALLOWLIST_KEY } from "./admin/CreateTournament";
import AllowListModal from "./AllowListModal";
import FundTournamentModal from "./FundButtonModal";
import { CardOptionsDropdown } from "./GameCardDropdown";


export function CardOptions({ tournament }: { tournament: Tournament }) {
    const [fundTournamentModalOpen, setFundTournamentModalOpen] = useState(false);
    const [allowListModalOpen, setAllowListModalOpen] = useState(false);

    const allowListKey = ALLOWLIST_KEY(tournament.tournamentId)

    const { data: allowListData, mutate: refetchAllowList } = useClerkSWR(
        allowListKey
    )

    return (
        <>
            {allowListModalOpen && <AllowListModal allowListModalOpen={allowListModalOpen} setAllowListModalOpen={setAllowListModalOpen} tournamentId={tournament.tournamentId} currentAllowList={allowListData?.data || []} refetchAllowList={refetchAllowList} />}
            {fundTournamentModalOpen && <FundTournamentModal tournament={tournament} fundModalOpen={fundTournamentModalOpen} setFundModalOpen={setFundTournamentModalOpen} />}
            <CardOptionsDropdown tournament={tournament} setFundTournamentModalOpen={setFundTournamentModalOpen} setAllowListModalOpen={setAllowListModalOpen} fundTournamentModalOpen={fundTournamentModalOpen} allowListModalOpen={allowListModalOpen} />
        </>
    )
}
