"use client"

import { ABI } from '@/app/tournament-abi';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import useDebounce from '@/hooks/useDebounce';
import { Tournament } from '@/lib/schema';
import { formatNumber } from '@/lib/utils';
import { useQueryClient } from '@tanstack/react-query';
import { Loader2Icon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { erc20Abi, parseUnits } from "viem";
import { useAccount, useBalance, useChainId, useReadContract, useSimulateContract, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { useCapabilities, useWriteContracts } from "wagmi/experimental";
import { handleInvalidatePrizePool } from './PrizePool';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { useToast } from "./ui/use-toast";

function FundTournamentModal({ tournament, fundModalOpen, setFundModalOpen }: { tournament: Tournament, fundModalOpen: boolean, setFundModalOpen: (open: boolean) => void }) {
    const { toast } = useToast();
    const { address, connector } = useAccount()

    const [amount, setAmount] = useState<string>('');
    const balance = useBalance({ address: address, token: tournament.paymentToken as `0x${string}` });
    const debouncedAmount = useDebounce(amount, 500); // 500ms debounce

    const [isLoading, setIsLoading] = useState(false);
    const [needsApproval, setNeedsApproval] = useState(false);
    const chainId = useChainId()
    const queryClient = useQueryClient()


    const { data: availableCapabilities } = useCapabilities({
        account: address,
        query: {
            enabled: Boolean(connector?.name === 'Coinbase Wallet')
        }
    });

    const capabilities = useMemo(() => {
        if (!availableCapabilities || !chainId) return {};
        const capabilitiesForChain = availableCapabilities[chainId];

        if (
            capabilitiesForChain["paymasterService"] &&
            capabilitiesForChain["paymasterService"].supported
        ) {
            return {
                paymasterService: {
                    url: `${process.env.NEXT_PUBLIC_BASE_SITE_URL}/api/paymaster`,
                },
            };
        }
        return {};
    }, [availableCapabilities, chainId]);

    // Define decimals based on the paymentTokenName
    const decimals = tournament.paymentTokenDecimals || 18

    const { data: simulateFundTournament } = useSimulateContract({
        address: tournament.address as `0x${string}`,
        abi: ABI,
        functionName: 'fundPrizePool',
        args: [BigInt(tournament.tournamentId), parseUnits(amount || '0', decimals)],
    });

    const { writeContract: fundTournament, data: fundTournamentData } = useWriteContract();

    const { writeContracts: writeFundTournamentContracts } = useWriteContracts({
        mutation: {
            onMutate() {
                setIsLoading(true);
            },
            onSuccess: async () => {
                setTimeout(() => {
                    toast({
                        title: "Tournament Funded",
                        description: `You have successfully funded the tournament with ${amount} ${tournament.paymentTokenSymbol}.`,
                    });
                    setFundModalOpen(false);
                    setIsLoading(false);
                    handleInvalidatePrizePool(queryClient, tournament.tournamentId, chainId, tournament.address as `0x${string}`);
                }, 6000);
            },
            onError() {
                setIsLoading(false);
            },
        }
    });



    const { data: waitForFundTransactionHash } = useWaitForTransactionReceipt({
        hash: fundTournamentData,
        confirmations: 1,
        query: {
            enabled: Boolean(fundTournamentData),
        },
    });

    useEffect(() => {
        if (!waitForFundTransactionHash) return;
        toast({
            title: "Tournament Funded",
            description: `You have successfully funded the tournament with ${amount} ${tournament.paymentTokenSymbol}.`,
        });
        setFundModalOpen(false);
        setIsLoading(false);
        setTimeout(() => {
            handleInvalidatePrizePool(queryClient, tournament.tournamentId, chainId, tournament.address as `0x${string}`);
        }, 4000);
    }, [waitForFundTransactionHash, amount, toast]);

    const { data: allowance, refetch: refetchAllowance } = useReadContract({
        address: tournament.paymentToken as `0x${string}`,
        abi: erc20Abi,
        functionName: "allowance",
        args: [address as `0x${string}`, tournament.address as `0x${string}`],
    });

    const { data: approvalConfig } = useSimulateContract({
        address: tournament.paymentToken as `0x${string}`,
        abi: erc20Abi,
        functionName: "approve",
        args: [tournament.address as `0x${string}`, parseUnits(amount || '0', decimals)],
    });

    const { writeContract: approveWrite, data: approveData } = useWriteContract({
        mutation: {
            onError() {
                setIsLoading(false);
            }
        }
    });
    const { data: approvalSuccess } = useWaitForTransactionReceipt({
        hash: approveData,
        confirmations: 1,
        query: {
            enabled: Boolean(approveData),
        }
    });

    useEffect(() => {
        if (!approvalSuccess) return;
        refetchAllowance();
        setIsLoading(false);
    }, [approvalSuccess]);

    useEffect(() => {
        const checkAllowance = async () => {
            if (debouncedAmount && address) {
                const parsedAmount = parseUnits(debouncedAmount, decimals);
                const currentAllowance = await refetchAllowance();
                if (!currentAllowance.data) return;
                if (currentAllowance && parsedAmount > currentAllowance.data) {
                    console.log('Setting approval to true');
                    setNeedsApproval(true);
                } else {
                    setNeedsApproval(false);
                }
            }
        };

        checkAllowance();
    }, [debouncedAmount, address, refetchAllowance, allowance]);

    const handleApproval = (amount: string) => {
        setIsLoading(true);
        if (connector?.name !== 'Coinbase Wallet') {
            if (!approvalConfig) return;
            approveWrite(approvalConfig.request);
        } else {
            const writes = []

            writes.push({
                address: tournament.paymentToken as `0x${string}`,
                abi: erc20Abi,
                functionName: "approve",
                args: [tournament.address as `0x${string}`, parseUnits(amount || '0', decimals)],
            })
            writes.push({
                address: tournament.address as `0x${string}`,
                abi: ABI,
                functionName: "fundPrizePool",
                args: [
                    BigInt(tournament.tournamentId),
                    parseUnits(amount || '0', decimals)
                ],
            })
            writeFundTournamentContracts({
                contracts: writes,
                capabilities,
            });
            setIsLoading(false);
        }
    };

    const handleSubmit = () => {
        if (!allowance || parseUnits(amount || '0', decimals) > allowance) {
            handleApproval(amount);
        } else {
            setIsLoading(true);
            if (connector?.name !== 'Coinbase Wallet') {
                if (!simulateFundTournament) return;
                fundTournament(simulateFundTournament.request);
            } else {
                const writes = []
                if (needsApproval) {
                    writes.push({
                        address: tournament.paymentToken as `0x${string}`,
                        abi: erc20Abi,
                        functionName: "approve",
                        args: [tournament.address as `0x${string}`, parseUnits(amount || '0', decimals)],
                    })
                }
                writes.push({
                    address: tournament.address as `0x${string}`,
                    abi: ABI,
                    functionName: "fundPrizePool",
                    args: [
                        BigInt(tournament.tournamentId),
                        parseUnits(amount || '0', decimals)
                    ],
                })
                writeFundTournamentContracts({
                    contracts: writes,
                    capabilities,
                });
                setIsLoading(false);
            }
        }
    };

    return (
        <Dialog open={fundModalOpen} onOpenChange={setFundModalOpen}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Fund Tournament</DialogTitle>
                    <DialogDescription>
                        Enter the amount of {tournament.paymentTokenSymbol} you want to contribute to tournament {tournament.tournamentId}.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-col space-y-4 py-4">
                    <p>Balance: {formatNumber(balance.data?.value.toString() || '0', decimals)} {balance.data?.symbol}</p>

                    <div className="flex items-center">
                        <Label htmlFor="amount" className="w-20">
                            Amount
                        </Label>
                        <Input
                            id="amount"
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="flex-grow w-40"
                            placeholder={`Enter amount in ${tournament.paymentTokenSymbol}`}
                        />
                    </div>
                    <Button
                        onClick={handleSubmit}
                        className="w-full"
                        disabled={isLoading}
                    >
                        {isLoading && (
                            <Loader2Icon className='animate-spin w-6 h-6 mr-2' />
                        )}
                        {needsApproval && connector?.name === 'Coinbase Wallet' ? 'Fund' : needsApproval ? 'Approve' : 'Fund Tournament'}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default FundTournamentModal;
