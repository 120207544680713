"use client";

import { refreshCommunityTournaments } from "@/server-actions/refresh-community-tournaments";
import { ClipboardIcon } from "lucide-react";
import Image from "next/image";
import { useEffect } from "react";
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";

function TournamentCreatedModal({ tournamentId, gameId, selectedNetwork }: { tournamentId: string, selectedNetwork: string, gameId: string }) {
    const { toast } = useToast();

    useEffect(() => {
        if (!selectedNetwork) return;
        return () => {
            refreshCommunityTournaments(selectedNetwork)
                .catch(error => console.error('Failed to refresh tournaments:', error));
        };
    }, [selectedNetwork]);

    const handleCreatorShare = (platform: 'twitter' | 'discord') => {
        const message = `I've just created an epic challenge on Metacade. Join the tournament and see if you can beat me. @Metacade_ https://tournaments.metacade.co/game/${gameId}?tid=${tournamentId}`;
        switch (platform) {
            case 'twitter':
                const twitterUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(message)}`;
                window.open(twitterUrl, '_blank', 'noopener,noreferrer');
                break;
        }
    };

    return (
        <div className="flex flex-col items-center space-y-6 py-8">
            <h2 className="text-2xl font-bold text-white">Tournament Created Successfully!</h2>
            <p className="text-gray-400 text-center">Your tournament will be live and accessible in just a few moments. Anyone can fund the tournament by selecting the &quot;Fund&quot; option from the vertical dots dropdown menu on the tournament.</p>
            <div className="flex items-center space-x-2  p-3 rounded-lg">
                <p className="text-sm font-medium text-white">https://tournaments.metacade.co/game/{gameId}?tid={tournamentId}</p>
                <Button
                    onClick={() => {
                        navigator.clipboard.writeText(`https://tournaments.metacade.co/game/${gameId}?tid=${tournamentId}}`);
                        toast({
                            title: "Copied to clipboard",
                            description: "The tournament link has been copied to your clipboard.",
                        });
                    }}
                    className="ml-2 p-2 bg-purple-300 hover:bg-purple-400 text-white rounded-full"
                >
                    <ClipboardIcon className="h-4 w-4" />
                </Button>
            </div>
            <div className="flex space-x-4">
                <Button onClick={() => handleCreatorShare('twitter')} className="flex items-center space-x-2 bg-blue-400 hover:bg-blue-500 text-white px-4 py-2 rounded-full transition duration-300 ease-in-out">
                    <Image src={"/x.webp"} alt="X" width={20} height={20} />
                    <span>Share on X</span>
                </Button>
            </div>
        </div>
    );

}

export default TournamentCreatedModal;
