import { Clock10 } from 'lucide-react';
import React, { useEffect, useState } from 'react';

interface CountdownClockProps {
    endTime: string;
}

const CountdownClock: React.FC<CountdownClockProps> = ({ endTime }) => {
    const [timeLeft, setTimeLeft] = useState<string>('');

    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date().getTime();
            const end = new Date(endTime).getTime();
            const distance = end - now;

            if (distance < 0) {
                clearInterval(timer);
                setTimeLeft('Ended');
            } else {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [endTime]);

    return (
        <div className="flex items-center space-x-2 text-white py-2 px-3 rounded-md shadow-md">
            <Clock10 className="w-4 h-4" />
            <span className="font-medium">{timeLeft}</span>
        </div>
    );
};

export default CountdownClock;
