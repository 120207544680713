"use client"

import { ABI } from '@/app/tournament-abi';
import { formatNumber, formatUpperCaseString } from '@/lib/utils';
import { QueryClient } from '@tanstack/react-query';
import { Loader } from 'lucide-react';
import { useChainId, useReadContract } from "wagmi";
import { TournamentTooltip } from './Tooltip';

export const handleInvalidatePrizePool = (client: QueryClient, tournamentId: number, chainId: number, address: string) => {
    const queryKey = ["readContract", { "address": address, "functionName": "prizePools", "scopeKey": `prizePools-${tournamentId}`, "args": [BigInt(tournamentId)], "chainId": chainId }]
    client.invalidateQueries({ queryKey });
};

function PrizePool({ children, address, tournamentId, winnerSpread, chainId, network, paymentTokenSymbol, paymentTokenDecimals }: { children: React.ReactNode, address: string, tournamentId: number, winnerSpread: number, chainId: number, network: string, paymentTokenSymbol: string, paymentTokenDecimals: number }) {
    const _chainId = useChainId()

    const { data, isFetching, error } = useReadContract({
        abi: ABI,
        address: address as `0x${string}`,
        functionName: 'prizePools',
        query: {
            enabled: Boolean(_chainId === chainId && tournamentId && address),
        },
        scopeKey: `prizePools-${tournamentId}`,
        args: [BigInt(tournamentId as number)],
    })

    if (isFetching) return <Loader />

    return (
        <div className="flex items-center space-x-2 min-h-6" >
            {children}
            <span className="text-white font-semibold font-bai">
                {_chainId !== chainId ? (
                    <p>{'On ' + formatUpperCaseString(network)}</p>
                ) : error ? (
                    <p>Connect Wallet</p>
                ) : (
                    <>
                        {`${formatNumber(BigInt((data || 0) as number).toString(), paymentTokenDecimals)} ${paymentTokenSymbol}`}
                        <TournamentTooltip text={`The number of top players who will share the prize pool`}>
                            <span className="ml-2 text-sm text-gray-400">({winnerSpread})</span>
                        </TournamentTooltip>
                    </>
                )}
            </span>
        </div>
    )
}

export default PrizePool