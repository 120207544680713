"use client"

import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog'
import { Game, Token } from '@/lib/schema'
import { useUser } from '@clerk/nextjs'
import { useState } from 'react'
import { useAccount } from 'wagmi'
import CreateTournament from './CommunityCreateTournament'
import { } from './DropdownGameSelector'

function CreateTournamentButton({ games, tokens, admin }: { games: Game[], tokens: Token[], admin?: boolean }) {
    const { isConnected } = useAccount()
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useUser()

    const wallet = user?.unsafeMetadata.wallet
    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
    };

    return (
        <>
            <Dialog open={isOpen} onOpenChange={handleOpenChange}>
                <DialogTrigger asChild>
                    <Button
                        variant="secondary"
                        className="flex items-center"
                        disabled={!isConnected || !wallet}
                        onClick={() => setIsOpen(true)}
                    >
                        Create Tournament
                    </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[525px]">
                    <DialogHeader>
                        <DialogTitle>Create a Tournament</DialogTitle>
                        <DialogDescription>
                            {!admin && (
                                <>
                                    Choose the game and its configuration. We&apos;ll manage the rest. As the host, you will earn 25% of each credit purchase swap for this tournament. This will go directly into your linked wallet.
                                </>
                            )}
                        </DialogDescription>
                    </DialogHeader>
                    <CreateTournament games={games} tokens={tokens} admin={admin} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateTournamentButton
