

"use client"

import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog'
import { handleCreateTokenRequest } from '@/server-actions/community-token-request'
import { useUser } from '@clerk/nextjs'
import { HelpCircle } from 'lucide-react'
import { useState } from 'react'
import { isAddress } from 'viem'
import AdminNetworkSelector from './AdminNetworkSelector'
import { } from './DropdownGameSelector'
import { TournamentTooltip } from './Tooltip'
import { Input } from './ui/input'
import { Label } from './ui/label'
import { useToast } from './ui/use-toast'

function RequestTokenModal({ setTokenRequestModalOpen, tokenRequestModalOpen, children }: { setTokenRequestModalOpen: (open: boolean) => void, tokenRequestModalOpen: boolean, children: React.ReactNode }) {
    const { user } = useUser()
    const { toast } = useToast()
    const [network, setNetwork] = useState<string>('base')
    const [royaltyAddress, setRoyaltyAddress] = useState<string>('')
    const [token, setToken] = useState<string>('')

    const handleOpenChange = () => {
        setTokenRequestModalOpen(!tokenRequestModalOpen);
    };

    const handleSubmit = async () => {

        if (!isAddress(token)) {
            toast({
                title: "Invalid Token Address",
                description: "Please enter a valid token address.",
                variant: "destructive",
            });
            return
        }
        if (!isAddress(royaltyAddress)) {
            toast({
                title: "Invalid Royalty Address",
                description: "Please enter a valid royalty address.",
                variant: "destructive",
            });
            return
        }
        await handleCreateTokenRequest({ createTokenRequestProps: { address: token, network: network, beneficiaryAddress: royaltyAddress } })
        toast({
            title: "Token Request Submitted",
            description: "Your token request has been submitted and will be reviewed by Metacade.",
        })
        setTokenRequestModalOpen(false)
    }

    return (
        <>
            <Dialog open={tokenRequestModalOpen} onOpenChange={handleOpenChange}>
                <DialogTrigger asChild>
                    {children}
                </DialogTrigger>
                <DialogContent className="sm:max-w-[525px]">
                    <DialogHeader>
                        <DialogTitle>Request a Token</DialogTitle>
                        <DialogDescription>
                            Request a new token to be added to the platform. As the token owner, you will receive 10% of the royalties from all credit swaps on any tournament that uses the token.
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex flex-col py-4 space-y-6">
                        <div className="flex flex-col space-y-6 mt-4 text-left">
                            <div className='flex items-center space-x-4'>
                                <Label htmlFor="network" className="w-32 text-left">
                                    Network
                                </Label>
                                <AdminNetworkSelector network='base' selectedNetwork={network} setSelectedNetwork={setNetwork} />
                            </div>
                            <div className='flex items-center space-x-4'>
                                <Label htmlFor="token" className="w-32 text-left">
                                    Token Address
                                </Label>
                                <div className="flex-grow">
                                    <Input
                                        id="token"
                                        value={token}
                                        className='w-full'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setToken(event.target.value)}
                                    />
                                </div>
                                <TournamentTooltip text='Requesting a token will allow anyone to create tournaments with it, a request must be approved by Metacade and may take up to 48 hours to process.'>
                                    <HelpCircle className="text-white flex-shrink-0" height={25} width={25} />
                                </TournamentTooltip>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <Label htmlFor="royalty-address" className="w-32 text-left">
                                    Royalty Address
                                </Label>
                                <div className="flex-grow">
                                    <Input
                                        id="royalty-address"
                                        value={royaltyAddress}
                                        className='w-full'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRoyaltyAddress(event.target.value)}
                                    />
                                </div>
                                <TournamentTooltip text='The address that will receive 10% of the royalties from all credit swaps on the token.'>
                                    <HelpCircle className="text-white flex-shrink-0" height={25} width={25} />
                                </TournamentTooltip>
                            </div>
                            <Button onClick={handleSubmit} className="mt-4">Submit Request</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RequestTokenModal


