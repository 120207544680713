import { Card } from "@/components/ui/card";
import { Tournament } from "@/lib/schema";
import { userIdToBase64 } from "@/lib/utils";
import { useUser } from "@clerk/nextjs";
import { CheckCircleIcon, EyeClosed, Gamepad, Gamepad2, LockIcon, Trophy, UserIcon } from "lucide-react";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import CountdownClock from "./CountdownClock";
import CreditsBox from "./CreditsBox";
import { CardOptions } from "./GameCardOptions";
import TokenIcon from "./TokenIcon";
import { TournamentTooltip } from "./Tooltip";
import { Button } from "./ui/button";

const PrizePool = dynamic(() => import("./PrizePool"), { ssr: false })
const JoinTournamentButton = dynamic(() => import("./JoinTournamentButton"), { ssr: false })

const HalloweenEmoji = () => (
    <span className="ml-1 text-sm" role="img" aria-label="halloween">
        {Math.random() > 0.5 ? '🎃' : '👻'}
    </span>
);

interface TournamentGameCardProps {
    tournament: Tournament;
    activeTournaments: Tournament[];
    selected: boolean;
    primaryColor: string;
    secondaryColor: string;
    logoUrl: string;
}

function TournamentGameCard({ tournament, activeTournaments, selected, primaryColor, secondaryColor, logoUrl }: TournamentGameCardProps) {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const { isSignedIn } = useUser()
    const videoRef = useRef<HTMLVideoElement>(null);
    const tournamentIsGatedByAllowlist = tournament.gatedByWalletAllowList
    const tournamentIsBlindLeaderboard = tournament.blindLeaderboard

    const isHalloween = process.env.NEXT_PUBLIC_ENABLE_HALLOWEEN === "true"

    useEffect(() => {
        if (tournament.coverAnimationUrl) {
            const video = document.createElement('video');
            video.src = tournament.coverAnimationUrl;
            video.preload = "auto";
            video.muted = true;
            video.playsInline = true;
            video.oncanplaythrough = () => setIsVideoLoaded(true);
            video.load();
        }
    }, [tournament.coverAnimationUrl]);

    const handleMouseEnter = () => {
        setIsHovering(true);
        if (videoRef.current && isVideoLoaded) {
            videoRef.current.play();
        }
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    const hasCompleted = tournament.status === 'COMPLETE' || +new Date(tournament.endTime) / 1000 < +new Date() / 1000 ? true : false;

    return (
        <Card
            className={`col-span-2 p-2 block overflow-hidden relative transition-shadow duration-300 active-card rounded-xl hover:shadow-lg cursor-pointer ${selected ? 'border-2' : ''
                } ${isHalloween ? 'halloween-card' : ''}`}
            style={{
                '--primary-color': primaryColor,
                '--secondary-color': secondaryColor,
                ...(selected && { borderColor: primaryColor ? `${primaryColor}33` : 'rgba(255, 255, 255, 0.2)' }),
            } as React.CSSProperties}
        >
            {/* Updated Triangle cutout with animated gradient */}
            {primaryColor && secondaryColor && (
                <div
                    className="absolute top-0 left-0 w-12 h-12 overflow-hidden"
                    style={{
                        clipPath: 'polygon(0 0, 0% 100%, 100% 0)',
                        zIndex: 10,
                    }}
                >
                    <div
                        className="absolute inset-0 w-full h-full particle-container create-particles"
                        style={{
                            background: `
                                linear-gradient(45deg, 
                                    var(--primary-color) 0%, 
                                    var(--secondary-color) 25%, 
                                    var(--primary-color) 50%, 
                                    var(--secondary-color) 75%, 
                                    var(--primary-color) 100%)
                            `,
                            backgroundSize: '400% 400%',
                        }}
                    >
                        <div
                            className="absolute inset-0 w-full h-full"
                            style={{
                                background: `linear-gradient(45deg, var(--primary-color), var(--secondary-color))`,
                                animation: 'shimmer 2s infinite linear',
                            }}
                        />
                        <style jsx>{`
                            @keyframes shimmer {
                                0% { opacity: 0.8; }
                                50% { opacity: 1; }
                                100% { opacity: 0.8; }
                            }
                        `}</style>
                    </div>
                </div>
            )}

            {/* Add cobweb images when Halloween is enabled */}
            {isHalloween && (
                <>
                    <Image
                        src="/halloween/spider-web-corner-1.png"
                        alt="Cobweb"
                        width={120}
                        height={120}
                        className="absolute top-0 left-0 opacity-75 pointer-events-none z-[1]"
                    />
                    <Image
                        src="/halloween/spider-web-corner-1.png"
                        alt="Cobweb"
                        width={120}
                        height={120}
                        className="absolute top-0 right-0 opacity-35 pointer-events-none z-[1] scale-x-[-1]"
                    />
                </>
            )}

            <Link prefetch={isSignedIn} href={`/tournament/${tournament.tournamentId}`} scroll={false}>
                <div
                    className="h-52 flex items-center justify-center overflow-hidden relative"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {tournament.coverImageUrl && (
                        <Image
                            src={tournament.coverImageUrl}
                            priority={true}
                            height={384}
                            width={244}
                            className="w-full h-full object-top rounded-xl"
                            alt="Game Logo"
                        />
                    )}
                    {tournament.coverAnimationUrl && isVideoLoaded && (
                        <video
                            ref={videoRef}
                            src={tournament.coverAnimationUrl}
                            className={`absolute inset-0 w-full h-full object-cover transition-opacity rounded-xl duration-300 ${isHovering ? 'opacity-100' : 'opacity-0'}`}
                            muted
                            playsInline
                            loop
                        />
                    )}
                    {logoUrl && (
                        <Image
                            src={logoUrl}
                            alt="Brand Logo"
                            width={40}
                            height={40}
                            className="absolute top-1 left-1 w-10 h-10 object-contain z-20 rounded-full shadow-border"
                        />
                    )}
                    <div className="absolute bottom-0 left-0 z-20 opacity-75">
                        <TokenIcon tokenImage={tournament.paymentTokenName} tokenSymbol={tournament.paymentTokenSymbol} />
                    </div>
                    {tournamentIsGatedByAllowlist && tournamentIsBlindLeaderboard ? (
                        <>
                            <div className="absolute bottom-8 right-0 opacity-75 bg-secondary rounded-xl text-white text-xs font-semibold py-1 px-2 shadow-md z-10 flex items-center">
                                <LockIcon className="w-4 h-4 mr-1" />
                                Allowlist Only
                            </div>
                            <div className="absolute bottom-1 right-0 opacity-75 bg-secondary rounded-xl text-white text-xs font-semibold py-1 px-2 shadow-md z-10 flex items-center">
                                <EyeClosed className="w-4 h-4 mr-1" />
                                Blind Leaderboard
                            </div>
                        </>
                    ) : (
                        <>
                            {tournamentIsGatedByAllowlist && (
                                <div className="absolute bottom-1 right-0 opacity-75 bg-secondary rounded-xl text-white text-xs font-semibold py-1 px-2 shadow-md z-10 flex items-center">
                                    <LockIcon className="w-4 h-4 mr-1" />
                                    Allowlist Only
                                </div>
                            )}
                            {tournamentIsBlindLeaderboard && (
                                <div className="absolute bottom-1 right-0 opacity-75 bg-secondary rounded-xl text-white text-xs font-semibold py-1 px-2 shadow-md z-10 flex items-center">
                                    <EyeClosed className="w-4 h-4 mr-1" />
                                    Blind Leaderboard
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Link>

            <div className="mt-1 flex justify-center">
                {hasCompleted ? (
                    <div className="py-2 px-3 rounded-md shadow-md text-center" style={{ backgroundColor: 'var(--primary-color)', color: 'white' }}>
                        Tournament Ended
                    </div>
                ) : (
                    <div className="flex justify-center w-full">
                        <CountdownClock endTime={tournament.endTime} />
                    </div>
                )}
            </div>

            <div className="space-y-1">
                <div className="mt-2">
                    <div className="flex items-center space-x-2 whitespace-nowrap overflow-hidden">
                        {tournament.communityName ? (
                            <div className="relative w-[36px] h-[36px]">
                                <div
                                    className="absolute inset-0 rounded-full animate-spin"
                                    style={{
                                        background: `conic-gradient(from 0deg, ${tournament.secondaryColor}, transparent)`,
                                    }}
                                ></div>
                                <Image
                                    src={tournament.createdByUserImage || '/default-avatar.png'}
                                    alt="Community Logo"
                                    width={33}
                                    height={33}
                                    className="rounded-full absolute inset-[2px] object-cover"
                                />
                            </div>
                        ) : (
                            <div className="rounded-full p-1" style={{ backgroundColor: 'var(--secondary-color)' }}>
                                <Gamepad2 className="stroke-current flex-shrink-0" style={{ color: 'var(--primary-color)' }} />
                            </div>
                        )}
                        {tournament.communityName ? (
                            <a href={tournament.communityX} target="_blank" rel="noopener noreferrer" className="text-white font-semibold truncate hover:underline">
                                {tournament.communityName}
                            </a>
                        ) : (
                            <span className="text-white font-semibold truncate">
                                {`${tournament.gameName} - ${tournament.tournamentId}`}
                                {isHalloween && <HalloweenEmoji />}
                            </span>
                        )}
                    </div>
                </div>


                <PrizePool address={tournament.address} tournamentId={tournament.tournamentId} winnerSpread={tournament.winnerSpread} network={tournament.network} chainId={tournament.chainId} paymentTokenSymbol={tournament.paymentTokenSymbol} paymentTokenDecimals={tournament.paymentTokenDecimals}>
                    <TournamentTooltip text={`The current amount of ${tournament.paymentTokenSymbol} in the prize pool`}>
                        <div className="rounded-full p-1" style={{ backgroundColor: 'var(--secondary-color)' }}>
                            <Trophy className='w-6 h-5' style={{ color: 'var(--primary-color)' }} />
                        </div>
                    </TournamentTooltip>
                </PrizePool>



                <div className="flex items-center space-x-2">
                    {tournament.createdByUsername && tournament.createdBy && tournament.createdByUsername !== "Metacade" ? (
                        <Link prefetch={false} href={`/profile/${userIdToBase64(tournament.createdBy)}`}>
                            <div className="flex items-center space-x-2">
                                <TournamentTooltip text="The user that created the tournament">
                                    <div className="rounded-full p-1" style={{ backgroundColor: 'var(--secondary-color)' }}>
                                        <UserIcon className="stroke-current" style={{ color: 'var(--primary-color)' }} />
                                    </div>
                                </TournamentTooltip>
                                <p className="text-white font-semibold">{tournament.createdByUsername}</p>
                            </div>
                        </Link>
                    ) : (
                        <>
                            <TournamentTooltip text="The user that created the tournament">
                                <div className="rounded-full p-1" style={{ backgroundColor: 'var(--secondary-color)' }}>
                                    <UserIcon className="stroke-current" style={{ color: 'var(--primary-color)' }} />
                                </div>
                            </TournamentTooltip>
                            <span className="text-white font-semibold flex items-center">Metacade <CheckCircleIcon className="w-3 h-3 text-green-300 ml-1" /></span>
                        </>
                    )}
                </div>

                <div className="flex items-center space-x-2">
                    <TournamentTooltip text="Practice your skills in free play">
                        <div className="rounded-full p-1" style={{ backgroundColor: 'var(--secondary-color)' }}>
                            <Gamepad className="stroke-current" style={{ color: 'var(--primary-color)' }} />
                        </div>
                    </TournamentTooltip>
                    <Link prefetch={isSignedIn} className="cursor-pointer" href={`/game/${tournament.gameId}`}>
                        <Button variant={'link'} className="text-current font-semibold text-base hover:animate-pulse p-0 h-0" style={{ color: 'var(--primary-secondary)' }}>Free Play</Button>
                    </Link>
                </div>

                <div className="flex items-center space-x-1">
                    <JoinTournamentButton key={`${tournament.tournamentId}_${tournament.gameId}`} activeTournaments={activeTournaments} tournament={tournament} hasCompleted={hasCompleted} />
                    <CardOptions tournament={tournament} />
                    <CreditsBox tournamentId={tournament.tournamentId} />
                </div>

            </div>
        </Card>
    )
}

export default TournamentGameCard;
