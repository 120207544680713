import { Badge } from "@/components/ui/badge";
import Image from "next/image";

function TokenIcon({ tokenImage, tokenSymbol }: { tokenImage: string, tokenSymbol: string }) {
    return (
        <div className="text-white">
            <Badge className="space-x-2 text-xs bg-secondary" variant={'outline'}>
                <Image src={`/${tokenImage}.webp`} alt="Token Icon" className="rounded-full" width={20} height={20} />
                <p>{tokenSymbol}</p>
            </Badge>
        </div>

    )
}

export default TokenIcon