"use client"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import { isAddress } from 'viem'
import { } from './DropdownGameSelector'
import { Button } from './ui/button'
import { Input } from './ui/input'
import { useToast } from './ui/use-toast'

function AddWalletModal({ handleAddWallet, children, setAddWalletModalOpen }: { handleAddWallet: (wallet: string) => void, children: React.ReactNode, setAddWalletModalOpen: (open: boolean) => void }) {
    const { toast } = useToast()
    const [wallet, setWallet] = useState<string>('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        if (!isAddress(wallet)) {
            toast({
                title: "Error",
                variant: "destructive",
                description: "Thats not a valid wallet address.",
            })
            return
        }
        try {
            setLoading(true);
            handleAddWallet(wallet)
            setTimeout(() => {
                setWallet('');
                setLoading(false);
            }, 1000);
        } catch (error) {
            toast({
                title: "Error",
                variant: "destructive",
                description: "Something went wrong.",
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Dialog>
                <DialogTrigger asChild>
                    {children}
                </DialogTrigger>
                <DialogContent className="sm:max-w-[625px]">
                    <DialogHeader>
                        <DialogTitle>Add Wallet</DialogTitle>
                        <DialogDescription>
                            Add a new wallet address to the allowlist for this tournament.
                        </DialogDescription>
                    </DialogHeader>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit()
                    }} className='mt-2'>
                        <span className="text-xs mb-1">Wallet Address</span>
                        <Input
                            placeholder="Enter wallet address"
                            value={wallet}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWallet(event.target.value)}
                        />
                        <Button type="submit" className="mt-4 text-white" disabled={loading}>{loading ? <Loader2 className="animate-spin" /> : 'Add'}</Button>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddWalletModal


