'use client';

import type { ButtonProps } from '@/components/ui/button';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useForwardedRef } from '@/hooks/useForwardRef';
import { cn } from '@/lib/utils';
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';

interface ColorPickerProps {
    value: string;
    onChange: (value: string) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
    onBlur?: () => void;
}

const ColorPicker = forwardRef<
    HTMLInputElement,
    Omit<ButtonProps, 'value' | 'onChange' | 'onBlur'> & ColorPickerProps
>(
    (
        { disabled, value, onChange, onBlur, name, open, setOpen, className, ...props },
        forwardedRef
    ) => {
        const ref = useForwardedRef(forwardedRef);
        const popoverRef = useRef<HTMLDivElement>(null);

        const parsedValue = useMemo(() => {
            return value || '#FFFFFF';
        }, [value]);

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
                    setOpen(false);
                }
            };

            if (open) {
                document.addEventListener('mousedown', handleClickOutside);
            }

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [open, setOpen]);

        return (
            <div className="relative">
                <Button
                    {...props}
                    className={cn('block', className)}
                    name={name}
                    onClick={() => setOpen(true)}
                    size='icon'
                    style={{
                        backgroundColor: parsedValue,
                    }}
                    variant='outline'
                    disabled={disabled}
                    onBlur={onBlur}
                >
                    <div />
                </Button>
                {open && (
                    <div
                        ref={popoverRef}
                        className="absolute z-50 mt-2 border rounded-md shadow-lg"
                        style={{ width: '200px' }}
                    >
                        <div className="p-2">
                            <HexColorPicker
                                color={parsedValue}
                                onChange={onChange}
                                style={{ width: '100%' }}
                            />
                            <Input
                                className="mt-2"
                                maxLength={7}
                                onChange={(e) => onChange(e?.currentTarget?.value)}
                                ref={ref}
                                value={parsedValue}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

ColorPicker.displayName = 'ColorPicker';

export { ColorPicker };
