"use client"
import {
    DollarSignIcon,
    EllipsisVertical,
    UserPlusIcon
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Tournament } from "@/lib/schema";
import { useUser } from "@clerk/nextjs";
import Image from "next/image";


export function CardOptionsDropdown({ tournament, setFundTournamentModalOpen, setAllowListModalOpen, fundTournamentModalOpen, allowListModalOpen }: { tournament: Tournament, setFundTournamentModalOpen: (open: boolean) => void, setAllowListModalOpen: (open: boolean) => void, fundTournamentModalOpen: boolean, allowListModalOpen: boolean }) {
    const { user } = useUser();

    const handleShare = (platform: 'twitter' | 'discord') => {
        const message = `Challenge me in this tournament on Metacade - https://tournaments.metacade.co/game/${tournament.gameId}?tid=${tournament.tournamentId}`;
        switch (platform) {
            case 'twitter':
                const twitterUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(message)}`;
                window.open(twitterUrl, '_blank', 'noopener,noreferrer');
                break;
        }
    };

    return (
        <DropdownMenu
            onOpenChange={() => { setTimeout(() => (document.body.style.pointerEvents = ""), 100) }}>
            <DropdownMenuTrigger asChild>
                <Button variant="secondary" size="icon" className="hover:border-white hover:border-2 rounded-full">
                    <EllipsisVertical className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                <DropdownMenuItem onClick={() => setFundTournamentModalOpen(!fundTournamentModalOpen)}>
                    <DollarSignIcon className="mr-2 h-4 w-4" />
                    <span>Fund Tournament</span>
                </DropdownMenuItem>
                {tournament.gatedByWalletAllowList && tournament.createdBy == user?.id && <DropdownMenuItem onClick={() => setAllowListModalOpen(!allowListModalOpen)}>
                    <UserPlusIcon className="mr-2 h-4 w-4" />
                    <span>Manage Allow List</span>
                </DropdownMenuItem>}
                {/* <DropdownMenuItem onClick={() => setAllowListModalOpen(!allowListModalOpen)}>
                    <UserPlusIcon className="mr-2 h-4 w-4" />
                    <span>Manage Allow List</span>
                </DropdownMenuItem> */}
                <DropdownMenuItem onClick={() => window.open(`https://t.me/metacade_tournaments_bot?start=play`, '_blank')}>
                    <Image className="mr-2 h-4 w-4" src="/telegram.png" alt="Telegram" width={16} height={16} />
                    <span>Play on Telegram</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleShare('twitter')}>
                    <Image className="mr-2 h-4 w-4" src="/x.webp" alt="X" width={20} height={20} />
                    <span>Share on X</span>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu >
    )
}
